import { post } from "./_request";

export async function login({
	email,
	password,
}: {
	email: string;
	password: string;
}): Promise<{ user: any; token: string }> {
	return post("auth", "login", { email, password });
}

export async function loginFromSuaEscolaIdeal(suaEscolaIdealToken: string): Promise<{ user: any; token: string }> {
	return post("auth", "loginFromSuaEscolaIdeal", { suaEscolaIdealToken });
}

export async function logout(): Promise<void> {
	return post("auth", "logout", {});
}
