export declare type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends Array<infer U>
		? Array<DeepPartial<U>>
		: T[P] extends ReadonlyArray<infer U>
		? ReadonlyArray<DeepPartial<U>>
		: DeepPartial<T[P]> | T[P];
};

export class BaseEntity<T> {
	constructor(partialObject?: DeepPartial<T>) {
		Object.assign(this, partialObject);
	}

	id: string;
	createdDate?: Date;
	updatedDate?: Date;
}
