import { CrudModel } from "./_crud";

export class UserBaseModel extends CrudModel {
	constructor() {
		super("user");
	}

	async getProfile() {
		return this.get("profile");
	}

	async getEadUrl(): Promise<string> {
		const { eadUrl } = await this.get("eadUrl");
		return eadUrl;
	}

	async updatePassword(currentPassword: string, password: string) {
		return this.patch(null, "password", { currentPassword, password });
	}
}

export default new UserBaseModel();
