import { GuardResponse } from "@/interfaces/guard_response";
import { User } from "@/interfaces/user";
import store from "@/store";

export async function RoleGuard(...roles: string[]): Promise<GuardResponse> {
	const user: User = await store.dispatch("auth/getUserData");
	let redirectOnFail = "/";

	if (user?.role === "USER") {
		redirectOnFail = "/todas-publicacoes";
	}

	return {
		// evita verificação de [undefined].includes(undefined)
		valid: roles.includes(user.role || null),
		redirectOnFail: user ? redirectOnFail : "login",
	};
}
