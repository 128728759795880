// eslint-disable-next-line no-unused-vars
import { CreateQueryParams } from "@nestjsx/crud-request";
import { Mutex } from "async-mutex";

import {
	crudQuery,
	crudRead,
	crudUpdate,
	crudDelete,
	crudDescription,
	crudCreate,
	crudMetrics,
	crudPost,
	crudGet,
	crudPatch,
} from "./_request";

export class CrudModel {
	public endpoint: string;
	public description: any;
	public additionalDescription: any = [];
	public relationModels: { fieldKey: string; model: any }[] = [];
	private descriptionMutex: Mutex;

	public manyToOneOptions: any[] = [];
	private manyToOneOptionsMutex: Mutex;

	constructor(
		endpoint: string,
		additionalDescription: FieldDescription[] = [],
		relationModels: { fieldKey: string; model: any }[] = [],
	) {
		this.descriptionMutex = new Mutex();
		this.endpoint = endpoint;
		this.additionalDescription = additionalDescription;
		this.relationModels = relationModels;

		this.manyToOneOptionsMutex = new Mutex();
	}

	async getDescription(): Promise<EntityDescription> {
		const releaseMutex = await this.descriptionMutex.acquire();
		try {
			if (!this.description) {
				this.description = (await crudDescription(this.endpoint)).concat(this.additionalDescription);
				this.description.forEach((fieldDescription: any) => {
					if (fieldDescription.kind === "relation") {
						fieldDescription.model = this.relationModels.find(
							relationModel => relationModel.fieldKey === fieldDescription.key,
						)?.model;
					}
				});
			}
		} finally {
			releaseMutex();
		}
		return this.description;
	}

	// should be overridden for custom label
	labelTransformer(entity: any) {
		return entity.name || "";
	}

	async loadManyToOneOptions(forceRefresh = false) {
		const releaseMutex = await this.manyToOneOptionsMutex.acquire(); // prevent repeated requests
		try {
			// only search if no options are cached
			if (!this.manyToOneOptions.length || forceRefresh) {
				const description = await this.getDescription();
				const searchOptions: any = {};
				const sortingField = description.find((field: any) => field.defaultSort);
				if (sortingField) {
					searchOptions.sort = [{ field: sortingField.key, order: sortingField.defaultSort?.toUpperCase() || "ASC" }];
				} else if (description.find((field: any) => field.key === "name")) {
					searchOptions.sort = [{ field: "name", order: "ASC" }];
				}
				const options = await this.search(searchOptions);
				for (const option of options) {
					option.label = this.labelTransformer(option);
				}
				this.manyToOneOptions = options;
			}
		} finally {
			releaseMutex();
		}
	}

	async create(data: any, command: string = "") {
		try {
			return await crudCreate(`${this.endpoint}${command ? "/" + command : ""}`, data); // await before return allows error catching
		} catch (error) {
			await this.handleError(<any>error);
		}
	}

	async search(
		queryParameters: CreateQueryParams,
		joinAll = false,
		joinKey: string | null = null,
		joinBlacklist: string[] = [],
	) {
		if (joinAll) {
			queryParameters.join = await this.getJoinableFields();
		} else if (joinKey) {
			const description = await this.getDescription();
			queryParameters.join = description
				.filter(
					(field: any) =>
						field.kind === "relation" &&
						field.key.includes(joinKey) &&
						!joinBlacklist.find(blacklistedKey => field.key.includes(blacklistedKey)),
				)
				.map((field: any) => ({ field: field.key }));
		}
		return crudQuery(this.endpoint, queryParameters);
	}

	async read(id: string | number, joinKey: string | null = null, joinBlacklist: string[] = []) {
		const queryParameters: { join?: any[] } = {};
		if (joinKey) {
			const description = await this.getDescription();
			queryParameters.join = description
				.filter(
					(field: any) =>
						field.kind === "relation" &&
						field.key.includes(joinKey) &&
						!joinBlacklist.find(blacklistedKey => field.key.includes(blacklistedKey)),
				)
				.map((field: any) => ({ field: field.key }));
		} else {
			queryParameters.join = await this.getJoinableFields();
		}
		return crudRead(this.endpoint, id, queryParameters);
	}

	async update(id: string | number, data: any) {
		try {
			return await crudUpdate(this.endpoint, id, data); // await before return allows error catching
		} catch (error) {
			await this.handleError(<any>error);
		}
	}

	async delete(id: string | number, auditNotes: string) {
		return crudDelete(this.endpoint, id, auditNotes);
	}

	async post(id: string | number | null, action: string, data?: any) {
		const entityId = id ? `${id}/` : "";
		return crudPost(this.endpoint, `${entityId}${action}`, data);
	}

	async patch(id: string | number | null, action: string, data?: any) {
		const entityId = id ? `${id}/` : "";
		return crudPatch(this.endpoint, `${entityId}${action}`, data);
	}

	async get(path: string, params?: any) {
		return crudGet(this.endpoint, path, params);
	}

	async getMetrics(
		metricsOptions: { groupBy: "DAY" | "WEEK" | "MONTH"; startDate: string; endDate: string },
		subEntity: string = "",
	) {
		return crudMetrics(this.endpoint, metricsOptions, subEntity);
	}

	private async getJoinableFields(): Promise<{ field: string }[]> {
		const description = await this.getDescription();
		let relationFields = description.filter((field: any) => field.kind === "relation");
		// remove one-to-many and many-to-many relations
		const toManyRelationsKeys = relationFields
			.filter((field: any) => ["one-to-many", "many-to-many"].includes(field.relationType))
			.map((field: any) => field.key);
		toManyRelationsKeys.forEach((key: string) => {
			relationFields = relationFields.filter((field: any) => !field.key.includes(key));
		});
		return relationFields.map((field: any) => ({ field: field.key }));
	}

	private async handleError(error: { response: any; statusCode: number; friendlyMessage?: string }) {
		error.friendlyMessage = error.response.message; // copia mensagem retornada pelo servidor
		const description = await this.getDescription();
		const getPropertyName = (fieldKey: string) =>
			description.find((field: any) => field.key === fieldKey)?.name || fieldKey;
		// concatena detalhes do erro na mensagem (normalmente para Bad Request)
		const errorDetails: string[] = error.response.properties?.map(
			(errorProperty: any) =>
				`${getPropertyName(errorProperty.property)}: ${errorProperty.errorMessages
					.filter((errorMessage: string) => errorMessage)
					.join(", ")}`,
		);
		if (errorDetails) {
			error.friendlyMessage += `\n${errorDetails?.join("\n")}`;
		}
		throw error;
	}
}

export class ValidationError {
	property: string;
	target: any;
	value: any;
	children?: ValidationError[];
	constraints?: any;
}

export class FieldDescription {
	name?: string;
	key: string;
	kind?:
		| "action" // usado localmente para adicionar ações às tabelas de resultados
		| "text"
		| "textarea"
		| "select"
		| "password"
		| "date"
		| "datetime"
		| "file"
		| "relation"
		| "color"
		| "checkbox"
		| "money";
	relationType?: "one-to-one" | "many-to-one" | "one-to-many" | "many-to-many" | "embedded";
	options?: { name: string; value: string }[];
	displayInResults?: boolean;
	defaultSort?: "asc" | "desc";
	vueMask?: string;
	validation?: {
		lengthMin?: number;
		lengthMax?: number;
		min?: number;
		max?: number;
		matchKey?: string;
		kind?: "string" | "integer" | "decimal" | "cpfOrCnpj" | "cpf" | "cnpj" | "email" | "phone" | "url" | "match";
	};
	sortable?: boolean;
	label?: string;
}

export type EntityDescription = FieldDescription[];
