import { Module } from "vuex";
import { login, loginFromSuaEscolaIdeal, logout } from "../../api/_auth";

import userBaseModel from "@/api/user.base.model";

const TOKEN_STORAGE_KEY = "token";

const authModule: Module<any, any> = {
	namespaced: true,
	state: {
		_userData: null,
		_token: null,
	},
	getters: {
		token(state): string {
			if (!state._token) {
				state._token = sessionStorage.getItem(TOKEN_STORAGE_KEY) ?? localStorage.getItem(TOKEN_STORAGE_KEY);
			}
			return state._token;
		},
		isLoggedIn(state, getters): boolean {
			return !!getters.token;
		},
	},
	mutations: {
		SET_TOKEN(state, { token, shouldPersist }: { token: string; shouldPersist?: boolean }) {
			state._token = token;
			if (shouldPersist) {
				localStorage.setItem(TOKEN_STORAGE_KEY, token);
			} else {
				sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
			}
			if (token === null) {
				localStorage.removeItem(TOKEN_STORAGE_KEY);
				sessionStorage.removeItem(TOKEN_STORAGE_KEY);
			}
		},
		SET_USER_DATA(state, userData) {
			state._userData = userData;
		},
	},
	actions: {
		async getUserData({ commit, state }) {
			if (!state._userData) {
				commit("SET_USER_DATA", await userBaseModel.getProfile());
			}
			return state._userData;
		},

		async login(
			{ commit },
			{ email, password, shouldPersistSession, suaEscolaIdealToken }: { email: string; password: string; suaEscolaIdealToken?: string; shouldPersistSession?: boolean },
		) {
			let token;

			if (suaEscolaIdealToken) {
				({token} = await loginFromSuaEscolaIdeal(suaEscolaIdealToken));
			} else {
				({ token } = await login({ email, password }));
			}

			commit("SET_TOKEN", { token, shouldPersist: shouldPersistSession });
		},

		async logout({ commit, getters }) {
			try {
				if (getters.token) {
					await logout();
				}
			} catch (error) {
				console.error(error);
			}
			commit("SET_TOKEN", { token: null });
			commit("SET_USER_DATA", null);
		},
	},
};

export default authModule;
