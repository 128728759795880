import { $themeBreakpoints } from "@themeConfig";

export default {
	namespaced: true,
	state: {
		windowWidth: 0,
		shallShowOverlay: false,
		loadingCount: 0,
	},
	getters: {
		currentBreakPoint: state => {
			const { windowWidth } = state;
			if (windowWidth >= $themeBreakpoints.xl) return "xl";
			if (windowWidth >= $themeBreakpoints.lg) return "lg";
			if (windowWidth >= $themeBreakpoints.md) return "md";
			if (windowWidth >= $themeBreakpoints.sm) return "sm";
			return "xs";
		},
	},
	mutations: {
		UPDATE_WINDOW_WIDTH(state, val) {
			state.windowWidth = val;
		},
		TOGGLE_OVERLAY(state, val) {
			state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
		},
		INCREMENT_LOADING_COUNT(state) {
			state.loadingCount++;
		},
		DECREMENT_LOADING_COUNT(state) {
			state.loadingCount = state.loadingCount > 0 ? state.loadingCount - 1 : 0;
		},
	},
	actions: {
		showLoading({ commit }) {
			commit("INCREMENT_LOADING_COUNT");
		},
		hideLoading({ commit }) {
			commit("DECREMENT_LOADING_COUNT");
		},
	},
};
