import Vue from "vue";
import axios from "axios";
import store from "@/store";

import { API_URL } from "../../api.config";

const axiosIns = axios.create({ baseURL: API_URL });

axiosIns.interceptors.request.use(
	config => {
		const apiToken = store.getters["auth/token"];
		if (apiToken) {
			config.headers.Authorization = `Bearer ${apiToken}`;
		}
		return config;
	},
	error => Promise.reject(error),
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
