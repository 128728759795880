import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";
import { RoleGuard } from "@/guards/role-guard";
import { UserRole } from "@/interfaces/user";
import { guardsResolver } from "@/guards";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: "/",
			name: "dashboard",
			component: () => import("@/views/dashboard/Dashboard.vue"),
			meta: {
				pageTitle: "Dashboard",
				guards: [{ run: RoleGuard, args: UserRole.ADMINISTRATOR }],
				breadcrumb: [
					{
						text: "Dashboard",
						active: true,
					},
				],
			},
		},
		{
			path: "/administradores/:id?",
			name: "administradores",
			component: () => import("@/views/administrators/Administrators.vue"),
			meta: {
				pageTitle: "Administradores",
				guards: [{ run: RoleGuard, args: UserRole.ADMINISTRATOR }],
			},
		},
		{
			path: "/categorias/:id?",
			name: "categorias",
			component: () => import("@/views/blog/admin/BlogPostCategories.vue"),
			meta: {
				pageTitle: "Categorias",
				guards: [{ run: RoleGuard, args: UserRole.ADMINISTRATOR }],
			},
		},
		{
			path: "/subcategorias/:id?",
			name: "subcategorias",
			component: () => import("@/views/blog/admin/BlogPostSubcategories.vue"),
			meta: {
				pageTitle: "Subcategorias",
				guards: [{ run: RoleGuard, args: UserRole.ADMINISTRATOR }],
			},
		},
		{
			path: "/publicacoes/:id?",
			name: "publicacoes",
			component: () => import("@/views/blog/admin/BlogPosts.vue"),
			meta: {
				pageTitle: "Publicações",
				guards: [{ run: RoleGuard, args: UserRole.ADMINISTRATOR }],
			},
		},
		{
			path: "/pecas-de-marketing",
			name: "pecas-de-marketing",
			component: () => import("@/views/blog/user/BlogPostsMarketing.vue"),
			meta: {
				pageTitle: "Peças de Marketing",
				guards: [{ run: RoleGuard, args: UserRole.USER }],
			},
		},
		{
			path: "/templates",
			name: "templates",
			component: () => import("@/views/blog/user/BlogPostsTemplate.vue"),
			meta: {
				pageTitle: "Templates",
				guards: [{ run: RoleGuard, args: UserRole.USER }],
			},
		},
		{
			path: "/temas",
			name: "temas",
			component: () => import("@/views/blog/user/BlogPostsTheme.vue"),
			meta: {
				pageTitle: "Temas",
				guards: [{ run: RoleGuard, args: UserRole.USER }],
			},
		},
		{
			path: "/editar-design/:mediaId",
			name: "editar-design",
			component: () => import("@/views/blog/user/BlogPostTemplateEditor.vue"),
			meta: {
				guards: [{ run: RoleGuard, args: UserRole.USER }],
			},
		},
		{
			path: "/post/:id?",
			name: "post",
			component: () => import("@/views/blog/user/BlogPostDetails.vue"),
			meta: {
				guards: [{ run: RoleGuard, args: UserRole.USER }],
			},
		},
		{
			path: "/cursos",
			name: "cursos",
			component: () => import("@/views/ead/Ead.vue"),
			meta: {
				guards: [{ run: RoleGuard, args: UserRole.USER }],
			},
		},
		{
			path: "/todas-publicacoes",
			name: "todas-publicacoes",
			component: () => import("@/views/blog/user/BlogPosts.vue"),
			meta: {
				pageTitle: "Todas as publicações",
				guards: [{ run: RoleGuard, args: UserRole.USER }],
			},
		},
		{
			path: "/busca",
			name: "busca",
			component: () => import("@/views/blog/user/PostsSearch.vue"),
			meta: {
				pageTitle: "Busca",
				guards: [{ run: RoleGuard, args: UserRole.USER }],
			},
		},
		{
			path: "/datas-especiais",
			name: "datas-especiais",
			component: () => import("@/views/blog/user/BlogPostCommemorativeDate.vue"),
			meta: {
				pageTitle: "Datas Especiais",
				guards: [{ run: RoleGuard, args: UserRole.USER }],
			},
		},
		{
			path: "/gerenciamento-midias/:id?",
			name: "gerenciamento-midias",
			component: () => import("@/views/blog/admin/BlogPostMedia.vue"),
			meta: {
				pageTitle: "Gerenciamento de Midias",
				guards: [{ run: RoleGuard, args: UserRole.ADMINISTRATOR }],
			},
		},
		{
			path: "/calendario/:id?",
			name: "calendario",
			component: () => import("@/views/blog/admin/MonthlyCalendar.vue"),
			meta: {
				pageTitle: "Calendário",
				guards: [{ run: RoleGuard, args: UserRole.ADMINISTRATOR }],
			},
		},
		{
			path: "/login",
			name: "login",
			component: () => import("@/views/Login.vue"),
			meta: {
				layout: "full",
				isPublic: true,
				redirectIfLoggedIn: true,
			},
		},
		{
			path: "/error-404",
			name: "error-404",
			component: () => import("@/views/error/Error404.vue"),
			meta: {
				isPublic: true,
				layout: "full",
			},
		},
		{
			path: "*",
			redirect: "error-404",
		},
	],
});

router.beforeEach(async (to, _, next) => {
	const isLoggedIn = store.getters["auth/isLoggedIn"];

	if (isLoggedIn && to.meta.redirectIfLoggedIn) {
		return next({ name: "dashboard" });
	}

	if (!isLoggedIn && !to.meta.isPublic) {
		return next({ name: "login" });
	}

	if (to.meta.guards && to.meta.guards.length) {
		const resolvedGuards = await guardsResolver(to.meta.guards);
		const failedGuard = resolvedGuards.find(guard => !guard.valid);

		if (failedGuard) {
			return next({ path: failedGuard.redirectOnFail || "/" });
		}
	}

	return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById("loading-bg");
	if (appLoading) {
		appLoading.style.display = "none";
	}
});

export default router;
