import { GuardResponse } from "@/interfaces/guard_response";

interface GuardProps {
	args: any;
	run(args: any): Promise<GuardResponse>;
}

export async function guardsResolver(guards: GuardProps[]): Promise<GuardResponse[]> {
	const resolvedGuards = [];

	for (const guard of guards) {
		const resolvedGuard = await guard.run(guard.args);

		resolvedGuards.push(resolvedGuard);

		// evita execução dos próximos resolvedGuards.
		if (!resolvedGuard.valid) {
			break;
		}
	}

	return resolvedGuards;
}
