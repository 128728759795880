import { BaseEntity } from "./base_entity";

export enum UserRole {
	USER = "USER",
	ADMINISTRATOR = "ADMINISTRATOR",
}

export enum UserStatus {
	UNCONFIRMED_EMAIL = "UNCONFIRMED_EMAIL",
	APPROVAL_PENDING = "APPROVAL_PENDING",
	ACTIVE = "ACTIVE",
}

export enum Gender {
	MALE = "MALE",
	FEMALE = "FEMALE",
	OTHER = "OTHER",
}

export class User extends BaseEntity<User> {
	role: UserRole;
	status: UserStatus;
	email: string;
	name: string;
	birthDate: Date;
	phoneNumber: string;
	gender: Gender;
	cpf: string;
	about: string;
	profilePictureUrl: string;
}
